import { gsap } from "gsap";

let _root;

export default class Login {
    constructor() {

        _root = this;
        _root.cookie_name = 'aula_login';
        _root.password = 'alula1';

        this.init();
    }

    init() {
        const login_btn = document.querySelector('#login_btn');
        const login_overlay = document.querySelector('#alula-login');
        const login_input = document.querySelector('#login_input');

        // const $ = jQuery;
        // $(document).on('carbonFields.apiLoaded', function(e, api) {
        // alert("ok");
        // });

        if ( !_root.getCookie(_root.cookie_name) == 1 ) {
            gsap.set(login_overlay, { display: 'block' });

            if ('undefined' != typeof login_btn && null != login_btn) {
                _root.addEvent(login_btn, 'click', _root.onClickLogin);
                _root.addEvent(login_input, 'keyup', _root.onClickEnter);
                _root.addEvent(login_input, 'input', _root.onInputChange);
            }
            _root.unloadScrollBars();
        }

    }

    onInputChange(el) {
        el.target.classList.remove('error');
    }

    onClickEnter(el) {
        if (el.keyCode === 13) {
            _root.onClickLogin(el);
        }
    }

    onClickLogin(el) {
        el.preventDefault();
        const login_input = document.querySelector('#login_input');
        const login_overlay = document.querySelector('#alula-login');
        const content__msg = document.querySelector('.alula-login__content__msg');

        if (login_input.value === _root.password) {

            gsap.to(login_overlay, {
                duration: 1,
                autoAlpha: 0
            });

            _root.eraseCookie(_root.cookie_name);
            _root.setCookie(_root.cookie_name, '1', '1');
            _root.reloadScrollBars();

        } else {
            login_input.classList.add('error');
            content__msg.textContent = "The password entered is incorrect."

        }
    }

    reloadScrollBars() {
        document.documentElement.style.overflow = 'auto';
        document.body.scroll = 'yes';
    }

    unloadScrollBars() {
        document.documentElement.style.overflow = 'hidden';
        document.body.scroll = 'no';
    }

    setCookie(key, value, expiry) {
        var expires = new Date();
        expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
        document.cookie = key + '=' + value + ';path=/' + ';expires=' + expires.toUTCString();
    }

    getCookie(key) {
        var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
        return keyValue ? keyValue[2] : null;
    }

    eraseCookie(key) {
        var keyValue = _root.getCookie(key);
        _root.setCookie(key, keyValue, '-1');
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}
