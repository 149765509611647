import { gsap } from "gsap";
import textAppear from './text-appear';

let _isStarted = true;
let _countLoadedImages = 0;
let _allImages = 0;
let imagesLoaded = require('imagesLoaded');
let _preloader;
let _preloaderLogo;
let _preloaderProgress;
let _tlProgress;
let _root;
let _textAppear;

export default class Preloader {
    constructor() {
        _root = this;
        _textAppear = new textAppear();
        this.init();
    }

    init() {
        _preloader = document.getElementById('preloader');
        _preloaderLogo = _preloader.querySelector('.preloader-logo');
        _preloaderProgress = _preloader.querySelector('.preloader-progress');

        let _imagesLoaded = imagesLoaded(document.querySelectorAll('.img-preloader'), { background: 'div' });
        _allImages = _imagesLoaded.images.length;
        _imagesLoaded.on('always', _root.onAlways);
        _imagesLoaded.on('progress', _root.onProgress);

    }

    animationStart() {
        _tlProgress = new gsap.timeline({ delay: 1, onStart: _root.onInitStart, onComplete: _root.onInitComplete });

        _tlProgress.set(_preloader, { display: 'block', autoAlpha: 1 });
        _tlProgress.to(_preloader, 0.3, { autoAlpha: 0 });

        _tlProgress.set(_preloaderLogo, { display: 'block', autoAlpha: 1 });
        _tlProgress.to(_preloaderLogo, 0.3, { autoAlpha: 0 });

        _tlProgress.set(_preloaderProgress, { display: 'block', autoAlpha: 1 });
        _tlProgress.to(_preloaderProgress, 0.3, { autoAlpha: 0 });
    }

    onInitComplete() {
        if (_isStarted) {
            _isStarted = false;
        }

        _textAppear.init();
    }

    onInitStart() {
        //_root._textAppear.init();

        //alert("ok");
    }

    onAlways(instance) {
        _root.animationStart();
    }

    onProgress(instance, image) {
        _countLoadedImages++;
        const circle = document.getElementById('pathR');
        const radius = circle.getBoundingClientRect().width / 2;
        const circumference = radius * 2 * Math.PI;
        let width = new Number(circumference * (_countLoadedImages / _allImages));
        let offset = circumference.toFixed(0) - width.toFixed(0);
        let drawLength = circumference - offset;
        circle.style.strokeDasharray = circumference;
        circle.style.strokeDashoffset = circumference - drawLength;
    }

}
