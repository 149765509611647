import Swiper from 'swiper';
import { gsap, Power4 } from 'gsap';

let _root;
let _tl;
let _overlay;
let _overlayBg;
let _overlayClose;
let _navigationMobile;
let _swiperContainer;
let _swiper;
let _currentInc = 0;
let _currentMenuDepthEl;
let _menuIsVisible = false;

export default class NavigationMobile {
    constructor(root) {
        _root = this;
        _overlay = document.querySelector('#overlay');
        _overlayBg = _overlay.querySelector('.overlay__bg');
        _overlayClose = _overlay.querySelector('.overlay__close');
        _navigationMobile = document.querySelector('#navigation-mobile');
        _swiperContainer = _navigationMobile.querySelector('.scroll-swiper-ajax-content');

        this.initButton();

        this.setOutsideClick();

        window.addEventListener('resize', () => this.onResize());

    }

    onResize() {
        if (window.innerWidth > 992 && _menuIsVisible) {
            gsap.set(_overlayBg, { display: 'none', width: 0 });
            gsap.set(_navigationMobile, { display: 'none', autoAlpha: 1 });
            gsap.set(_overlayClose, { display: 'none', autoAlpha: 1 });
            gsap.set(_overlay, { display: 'none' });
            _root.reverseFunction();
        }
    }

    initButton() {
        const element = document.querySelector('#menu-toggle');

        if ('undefined' != typeof element && null != element) {
            _root.addEvent(element, 'click', _root.onClickMenuEvent);
        }
    }

    onClickMenuEvent(el) {
        el.preventDefault();

        _swiper = new Swiper(_swiperContainer, {
            direction: 'vertical',
            slidesPerView: 'auto',
            freeMode: true,
            init: false,
            scrollbar: {
                el: _swiperContainer.querySelector('.swiper-scrollbar')
            },
            mousewheel: true
        });

        if ('undefined' != typeof _swiperContainer && null != _swiperContainer) {
            _swiper.init();
        }

        _tl = gsap.timeline({ onComplete: _root.onCompleteTimelineLite, onReverseComplete: _root.reverseFunction });

        _root.unloadScrollBars();

        _tl.set(_overlay, { display: 'block' });
        _tl.set(_overlayBg, { display: 'block', width: 0 });

        _tl.set(_overlayBg, {transformOrigin: 'top right'});

        _tl.set(_navigationMobile, { display: 'block', autoAlpha: 0 });
        _tl.to(_overlayBg, 0.5, { width: '100%', transformOrigin: 'top right', ease: Power4.easeInOut });
        _tl.to(_navigationMobile, 0.5, { autoAlpha: 1 });
        _tl.set(_overlayClose, { display: 'block', autoAlpha: 0 });
        _tl.to(_overlayClose, 0.5, { autoAlpha: 1 });

        document.querySelectorAll('#mobile-menu .caret').forEach(_caret => {
            _root.addEvent(_caret, 'click', _root.onCaretClickEvent);
        });

        _root.setBackButton();

        _root.removeEvent(document.querySelector('#menu-toggle'), 'click', _root.onClickMenuEvent);
    }

    setOutsideClick() {
        document.addEventListener('click', (event) => {
            const withinBoundaries = event.composedPath().includes(_overlay)
            if ( !withinBoundaries ) {
                if (_menuIsVisible) {
                    _tl.reverse();
                }
            } 
        });
    }

    setBackButton() {
        const _back = document.querySelector('.mobile-menu-back');

        if ('undefined' != typeof _back && null != _back) {
            if (_currentInc === 0) {
                _root.removeEvent(_back, 'click', _root.onBackClickEvent);
                _back.style.display = "none";
            } else {
                _root.addEvent(_back, 'click', _root.onBackClickEvent);
                _back.style.display = "block";
            }
        }
    }

    setCurrentSubmenu(_trg) {
        _trg.parentElement.parentElement.querySelectorAll('.sub-menu').forEach(_sub => {
            _sub.style.display = "none";
        });
        _trg.parentElement.parentElement.querySelector('.sub-menu').style.display = "block";
    }

    onBackClickEvent(el) {
        _currentInc = _currentInc - 1;
        const menuHolder = document.querySelector('#nav-mob-holder');
        gsap.to(menuHolder, {
            duration: 0.5,
            x: -document.querySelector('#nav-mob-holder').getBoundingClientRect().width * (_currentInc),
            ease: Power4.easeInOut,
            onComplete() {
                _currentMenuDepthEl.parentElement.parentElement.querySelectorAll('#mobile-menu .sub-menu').forEach(_sub => {
                    _sub.style.display = "none";
                });
            }
        });
        _root.setBackButton();
        el.preventDefault();
    }

    onCaretClickEvent(el) {
        el.preventDefault();
        const _parent = el.currentTarget.parentElement.parentElement.parentElement.querySelector('.sub-menu');
        const _inc = parseInt(_parent.getAttribute('data-depth')) + 1;
        _root.menuAnimation(-(document.querySelector('#nav-mob-holder').getBoundingClientRect().width * _inc));
        _currentInc = _inc;
        _root.setBackButton();
        _root.setCurrentSubmenu(el.currentTarget);
        _currentMenuDepthEl = el.currentTarget;
    }

    menuAnimation(posX) {
        const menuHolder = document.querySelector('#nav-mob-holder');
        gsap.to(menuHolder, {
            duration: 0.5,
            x: posX,
            ease: Power4.easeInOut
        });
    }

    reloadScrollBars() {
        document.documentElement.style.overflow = 'auto';
        document.body.scroll = 'yes';
    }

    unloadScrollBars() {
        document.documentElement.style.overflow = 'hidden';
        document.body.scroll = 'no';
    }

    onCompleteTimelineLite() {
        _swiper.update();
        _root.addEvent(_overlayClose, 'click', _root.onClickCloseEvent);

        _menuIsVisible = true;

        document.querySelectorAll('#mobile-menu .menu-item').forEach(_item => {
            _root.addEvent(_item.querySelector('a'), 'click', _root.onClickMenuItem);
        });
    }

    onClickMenuItem(el) {
        _tl.reverse();
    }

    reverseFunction() {
        _root.reloadScrollBars();
        _swiper.destroy(true, true);
        _root.initButton();
        gsap.set(document.querySelector('#nav-mob-holder'), { x: 0 });
        _menuIsVisible = false;
    }

    onClickCloseEvent(el) {
        el.preventDefault();
        _tl.reverse();
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }
}